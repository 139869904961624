.dashboard__wrapper {
  width: 100%;
  height: 100vh;
}

.dashboard__navigation {
  width: 250px;
  position: fixed;
  background-color: #000;
  height:100vh;
}


.dashboard__content {
  width:calc(100% - 250px);
  margin-left: 250px;
  height:100vh;
  overflow-x: scroll;
}

.dashboard__content-profile {
  width:50%;
  margin:50px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dashboard__content-profile .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.biodata {
  margin-top:20px;
}

.loader {
  width:100%;
  height:300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile__details {
  width: 100%;
}
.profile__details h5 {
  font-family: "Avenir next Demi";
  font-size:20px;
}
.profile__details p {
  font-family: "Avenir next medium";
  font-size:17px;
}