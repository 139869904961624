@font-face {
  font-family: "Avenir Next regular";
  src: url("./fonts/avenir-next-regular.woff") format("woff"),
    url("./fonts/avenir-next-regular.eot") format("eot");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Avenir Next medium";
  src: url("./fonts/avenir-next-medium.woff") format("woff"),
    url("./fonts/avenir-next-medium.eot") format("eot");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Avenir Next demi";
  src: url("./fonts/avenir-next-demi.woff") format("woff"),
    url("./fonts/avenir-next-demi.eot") format("eot");
  font-style: normal;
  font-weight: 600;
}

.privacy__masthead {
  background: linear-gradient(180deg, #fecc09 0%, #d84627 100%);
  height: 500px;
  display: flex;
  flex-direction: column;
}
.masthead {
  width: 100%;
  height: 100vh;
  /* background-color: #ffcc00; */
  background-image: linear-gradient(
      95.23deg,
      rgba(0, 0, 0, 0.56) 0.78%,
      rgba(0, 0, 0, 0.38) 52.21%,
      rgba(0, 0, 0, 0) 83.98%
    ),
    url("./assets/twohappylovers.png");
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  background-size: cover;
}
.masthead__navigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Avenir Next medium";
  padding-top: 50px;
}
.masthead__navigation h1 {
  margin: 0;
  padding: 0;
  color: #fff;
}
.masthead__navigation img {
  width: 100px;
  margin-left: -24px;
}
.masthead__navigation a {
  color: #fff;
  padding: 0 24px;
  text-decoration: none;
  font-size: 20px;
}
ul {
  padding: 0;
  margin: 0;
}
.masthead__navigation h1 {
  font-family: "Avenir Next demi";
  color: #fff;
}

.masthead__usp {
  padding-right: 100px;
  margin-top: 80px;
}
.masthead__usp h4 span {
  display: flex;
  flex-direction: column;
  font-family: "Avenir Next demi";
  font-size: 44px;
  color: #fff;
}

.masthead .connections--img {
  width: 514px;
  height: 500px;
  object-fit: cover;
  margin-top: 80px;
  float: right;
}
.masthead__usp p {
  margin-top: 24px;
  margin-bottom: 48px;
  font-family: "Avenir Next medium";
  color: #fff;
}
.form {
  width: 100%;
  margin-top: 100px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.form .actions {
  display: flex;
}
.form .actions a {
  margin-right: 10px;
}
.form input {
  height: 44px;
  border: none;
  padding-left: 16px;
  border-radius: 4px;
  font-family: "Avenir Next regular";
  color: #0a024e;
  border: 1px solid #f3f3f3;
  font-size: 14px;
}

.form__name--wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.form__name--wrapper input {
  width: 50%;
}
.form__name--wrapper input:first-child {
  margin-right: 8px;
}

.form input:focus {
  outline: none;
}
.form button {
  height: 54px;
  padding: 0 12px;
  border-radius: 4px;
  border: none;
  background-color: #ffcc00;
  color: #0a024e;
  font-family: "Avenir Next medium";
  color: #0a024e;
  margin-top: 16px;
}

.marketing__usp {
  width: 100%;
  padding: 95px 0;
  background: rgba(255, 204, 0, 0.08);
}
.marketing__usp ul {
  display: flex;
  justify-content: center;
}
.discovery {
  width: 100%;
  padding: 95px 0;
}

.marketing__usp h4,
.discovery h4,
.match__container h4 {
  font-family: "Avenir Next demi";
  color: #0a024e;
}
.marketing__usp p,
.discovery p {
  font-family: "Avenir Next medium";
  width: 40%;
  margin: 0 auto;
  color: #0a024e;
  text-align: center;
}
.marketing__usp h4,
.marketing__usp p {
  text-align: center;
}
.marketing__usp--userCard {
  margin-bottom: 42px;
}
.marketing__usp--userCard img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-bottom: 24px;
  border-radius: 4px;
}
.marketing__usp--userCard h5 {
  font-family: "Avenir Next demi";
  color: #0a024e;
}
.marketing__usp--userCard span {
  font-family: "Avenir Next regular";
  color: #0a024e;
}

.callToAction {
  height: 60px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffcc00;
  text-decoration: none;
  border-radius: 4px;
  color: #0a024e;
  font-family: "Avenir Next medium";
  transition: 0.5s ease-in-out;
}
.callToAction:hover {
  color: #0a024e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.discovery__card {
  padding: 50px;
}
.discovery__card img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 10px;
}
.discovery--height {
  display: flex;
  align-items: center;
  justify-content: center;
}

.discovery__card h5 {
  text-align: left;
  font-family: "Avenir Next demi";
  color: #0a024e;
}

.discovery__card p {
  font-family: "Avenir Next regular";
  color: #0a024e;
  width: 100%;
  text-align: left;
}

.match__container {
  background: rgba(255, 204, 0, 0.08);
  padding: 95px 0;
}

.match__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
  color: #0a024e;
}
.match__card img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100%;
}
.match__card h6 {
  font-family: "Avenir Next medium";
  margin-top: 10px;
  font-size: 14px;
}
.match__card h5 {
  font-family: "Avenir Next demi";
  margin-top: 12px;
  font-size: 16px;
}
.match__card p {
  text-align: center;
  font-family: "Avenir Next regular";
  font-size: 14px;
}

.footer__container {
  background-color: #ffcc00;
  padding: 50px;
}

.footer__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Avenir Next medium";
}
.logoAndSocial h4 {
  margin-right: 55px;
}

.social--tag {
  width: 40px;
  height: 40px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 100%;
}
.social .social--tag:nth-child(2) {
  margin: 0 20px;
}
.footer__container p {
  color: rgba(10, 2, 78, 0.4);
}
.footer--links {
  padding: 24px;
}
.footer--links a {
  padding: 0 24px;
  text-decoration: none;
  color: #0a024e;
}

.modal__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal__wrapper h1,
.modal__wrapper p {
  text-align: center;
}
.modal__wrapper span {
  margin-top: 40px;
}

.modal__wrapper h1 {
  font-family: "Avenir Next demi";
  margin-top: 24px;
  font-size: 28px;
}
.modal__wrapper p {
  font-family: "Avenir Next medium";
  padding-top: 20px;
  padding-bottom: 20px;
}

.modal__share--container button {
  margin-left: 10px;
  margin-bottom: 20px;
}

.privacy__masthead--text {
  background-color: #fff;
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  border-radius: 10px;
  margin: auto;
}

.privacy--section {
  padding-top: 50px;
  padding-bottom: 50px;
}
.privacy--section h1,
.privacy--section h2,
.privacy--section h3,
.privacy__masthead h1 {
  font-family: "Avenir Next demi";
  margin-top: 40px;
}
.privacy__masthead--text h1 {
  margin: 0;
}
.privacy--section p,
ul {
  font-family: "Avenir Next regular";
}
.privacy--section ul {
  margin-bottom: 20px;
}
.rodal,
.rodal-mask {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed !important;
  padding: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.rodal-dialog {
  display: flex;
  margin: auto;
  height: auto !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  font-family: inherit;
  font-size: 1rem;
  padding: 1.25em;
  border-radius: 0.3125em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative !important;
  width: 32em;
  max-width: 100%;
}

.faq {
  padding-top: 100px;
  padding-bottom: 135px;
}

.pad-right {
  padding-right: 100px;
}

.pad-left {
  padding-left: 100px;
}
.faq h1 {
  font-size: 30px;
  color: var(--main-text);
  text-align: center;
  font-weight: 600;
  font-family: "Avenir Next demi";
}
.faq--listItem {
  margin-top: 60px;
}
.faq .faq--listItem h2 {
  font-weight: 600;
  font-size: 24px;
  color: var(--main-text);
  font-family: "Avenir Next demi";
}

.faq .faq--listItem p {
  font-size: 16px;
  color: #25386b;
  font-family: "Avenir Next regular";
}

.privacy--section .col-sm-12 {
  padding: 0 150px;
}
.privacy--section p,
.privacy--section ul li {
  font-size: 17px;
  line-height: 2em;
  color: black;
}

.premium__header {
  height: 409px;
  background-image: linear-gradient(
      95.23deg,
      rgba(0, 0, 0, 0.56) 0.78%,
      rgba(0, 0, 0, 0.38) 52.21%,
      rgba(0, 0, 0, 0) 83.98%
    ),
    url("./assets/twohappylovers.png");
  object-fit: cover;
  background-position: center;
  background-size: cover;
  margin: 0 70px;
  border-radius: 5px;
  color: #fff;
}
.premium__wrapper .masthead__navigation {
  padding-top: 20px;
  padding-bottom: 40px;
  color: #000;
}

.premium__wrapper .masthead__navigation a {
  color: #000;
}

.premium__header h1 {
  font-size: 36px;
  font-family: "Avenir Next demi";
}
.premium__header p {
  font-size: 20px;
  margin-top: 12px;
  font-family: "Avenir Next medium";
}

.premium__header .button,
.premium__reasons .button {
  display: flex;
  width: 233px;
  height: 70px;
  font-family: "Avenir Next medium";
  background: #ffcc00;
  border-radius: 10px;
  color: #000;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-top: 50px;
}

.premium__header .details {
  height: 409px;
  padding-left: 54px;
}

.active__link {
  color: #ffa500 !important;
}
.premium__reasons {
  padding: 70px 0;
  background: rgba(255, 230, 140, 0.1);
}
.premium__reasons h1 {
  font-size: 40px;
  line-height: 66px;
  font-family: "Avenir Next demi";
  color: #000000;
}

.card__options {
  padding: 50px 0;
}

.card__value {
  width: 100px;
  height: 100px;
  background: rgba(255, 204, 0, 0.19);
  border-radius: 100px;
}
.premium__card h6 {
  color: #000000;
  font-size: 20px;
  font-family: "Avenir Next demi";
  margin:20px 0 0px 0;
}

.premium__card p {
  color: #000000;
  font-size: 16px;
  font-family: "Avenir Next medium";
}

@media (min-width: 1920px) {
  .masthead__usp {
    margin-top: 170px;
  }
}

@media (max-width: 768px) {
  .privacy--section .col-sm-12 {
    padding: 0 15px;
  }
  .privacy--section p,
  .privacy--section ul li {
    font-size: 14px;
    line-height: 2em;
    color: black;
  }

  .masthead .connections--img {
    width: 300px;
    height: 292px;
    margin-top: 0;
  }
  .masthead__usp {
    margin-top: 0;
  }
  .masthead__navigation h1 img {
    width: 60px;
    margin-left: -18px;
  }
  .masthead__navigation {
    padding-top: 30px;
    padding-bottom: 50px;
  }
  .masthead__navigation a {
    font-size: 16px;
  }
  .discovery__card {
    padding: 20px;
  }
  .discovery__card img {
    height: 330px;
  }
  .discovery__card p {
    font-size: 14px;
  }

  /* .discovery p {
    width:57%;
  } */

  .marketing__usp--userCard img {
    height: 228px;
  }
  .marketing__usp--userCard h5 {
    font-size: 17px;
  }
  .marketing__usp--userCard span {
    font-size: 14px;
  }

  .masthead__usp h4 span {
    font-size: 32px;
  }
  .masthead__usp {
    padding: 0;
  }
  .masthead {
    height: auto;
    padding-bottom: 40px;
  }

  .masthead__navigation h1 {
    font-size: 20px;
  }
  .footer--links a {
    padding: 0 20px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .masthead__navigation img {
    width: 85px !important;
    margin-left: -20px;
  }
  .privacy__masthead {
    height: 300px;
  }
  .masthead {
    overflow: hidden;
  }
  .masthead__usp {
    padding-right: 0;
    padding-top: 32px;
  }
  .masthead__usp h4 span {
    font-size: 30px;
    font-weight: 800;
  }
  .masthead__usp p {
    font-weight: 600;
  }
  .form {
    flex-direction: column;
    padding: 10px;
    height: auto;
  }
  .masthead .connections--img {
    width: 100%;
    height: 536px;
    object-fit: cover;
    padding: 40px;
    margin-top: 00px;
  }
  .form input {
    width: 100%;
  }
  .form button {
    width: 100%;
    height: 48px;
  }
  .marketing__usp p {
    font-family: "Avenir Next regular";
    width: 100%;
  }

  .masthead__navigation {
    padding-top: 30px;
  }
  .masthead__navigation a {
    color: #fff;
    padding: 0 10px;
    text-decoration: none;
    font-size: 14px;
    font-family: "Avenir Next demi";
  }
  .marketing__usp--userCard {
    margin-bottom: 70px;
  }

  .discovery__card {
    padding: 0px;
  }
  .discovery p {
    width: 100%;
  }
  .discovery__card h5 {
    margin-top: 30px;
  }

  .callToAction {
    width: 100%;
  }

  .change-position {
    display: flex;
    flex-direction: column-reverse;
  }
  .discovery .row {
    margin-bottom: 100px;
  }
  .discovery__card img {
    height: 300px;
  }

  .footer__container {
    padding: 20px;
  }
  .footer--links {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer--links a {
    font-size: 12px;
    margin-bottom: 8px;
  }
  .social--tag {
    width: 34px;
    height: 34px;
  }
  .footer__container p {
    font-size: 12px;
  }

  .form__name--wrapper {
    flex-direction: column;
    margin-bottom: 0;
  }
  .form__name--wrapper input {
    width: 100%;
    margin-bottom: 8px;
  }

  .privacy__masthead--text {
    background-color: #fff;
    width: 94%;
    padding: 30px 0;
  }
  .privacy__masthead--text h1 {
    font-size: 20px;
  }

  .pad-right {
    padding-right: 15px;
  }

  .pad-left {
    padding-left: 15px;
  }
}
