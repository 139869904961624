body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login__wrapper {
  width: 100%;
  height: 100vh;
  background-color: #fafafe;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.login__wrapper--form {
  width: 470px;
  background: #ffffff;
  box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  backdrop-filter: blur(16px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 16px;
  padding: 30px;
}

.login__wrapper--form h1 {
  font-size: 24px;
  font-family: "Avenir Next Demi";
}
.login__wrapper--form span {
  font-size: 14px;
  font-family: "Avenir Next medium";
}

.or__container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.or__container span {
  font-size: 14px;
  font-family: "Avenir Next demi";
}

.google-login {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f7f9;
  border-radius: 10px;
  padding: 9px;
  width: 100%;
  cursor: pointer;
  font-family: "Avenir Next medium";
}
.google {
  margin-right: 15px;
}
.google-text {
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}

.input__wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.input__wrapper label {
  font-size: 12px;
  font-family: "Avenir Next medium";
  color: #0a024e;
}
.input__wrapper input {
  border-radius: 5px;
  padding: 10px 10px;
  border: 1px solid #f3f3f3;
  font-family: "Avenir Next medium";
}
.input__wrapper button {
  padding: 12px 30px;
  border: 1px solid #ffcc00;
  font-family: "Avenir Next medium";
  border-radius: 5px;
  margin-top: 12px;
  background-color: #ffcc00;
  color: #0a024e;
  font-family: "Avenir Next medium";
}

.login__wrapper--form .error {
  border: 1px solid red;
}

.login__wrapper--form input:focus {
  outline: none;
}

.quick__links {
  display: flex;
  width: 400px;
  margin-top: 30px;
  justify-content: space-between;
}

.quick__links a {
  font-size: 14px;
  color: #828282;
  text-decoration: none;
  font-family: "Avenir Next medium";
}
